import {
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { navigate, useParams } from '@reach/router'
import { Button, Toast, Alert } from '@refera/ui-web'
import Footer from '_/components/footer'
import Textfield from '_components/textfield'
import HeaderTitle from '_/components/header-title'
import React, { useCallback, useEffect, useState } from 'react'
import useStyles from './styles'
import { ROUTES } from '_/utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { currentServiceOrderSelector } from '_/modules/service-orders/selectors'
import { getServiceOrder, postSendRatingLink } from '_/modules/service-orders/actions'
import { Controller, useForm } from 'react-hook-form'
import { formatPhoneValueMask, numbersValid, phoneNumberMask } from '_/utils/helpers'
import * as Modal from '_components/modal/generic-modal'
import useRolePermission from '_/hooks/use-role-permission'

const defaultToast = { isOpen: false, type: '', message: '' }

export function SendRatingLink() {
  const serviceOrder = useSelector(currentServiceOrderSelector)
  const { serviceOrderId } = useParams()
  const styles = useStyles()
  const dispatch = useDispatch()
  const [numberFieldText, setNumberFieldText] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [toastInfo, setToastInfo] = useState(defaultToast)

  const { isTradesman } = useRolePermission()

  const link = `${process.env.REACT_APP_NEXT_PWA_APP_URL}/service-rating?token=${serviceOrder?.uuid}`

  const messageWhatsAppLinkToSend =
    `Oi, tudo bem?\n\n` +
    `Sou prestador de serviço parceiro da Refera, que realizou o serviço no seu imóvel.\n` +
    `Espero que você tenha ficado feliz com o resultado. Sua satisfação é muito importante para nós!\n\n` +
    `Para finalizar a solicitação, preciso da sua avaliação. Clique neste link e avalie o serviço:\n\n` +
    `${link}`

  const handleGetServiceOrder = useCallback(() => {
    dispatch(getServiceOrder(serviceOrderId))
  }, [dispatch, serviceOrderId])

  useEffect(() => {
    handleGetServiceOrder()
  }, [handleGetServiceOrder])

  const { control, handleSubmit, watch } = useForm()

  const numberSelected = watch('phone')

  const showTextField = numberSelected === 'other'

  const GoBackServiceOrder = useCallback(async () => {
    dispatch(getServiceOrder(serviceOrderId)).then(() => {
      navigate(
        `${isTradesman ? ROUTES.DETAIL_SERVICE_ORDER : ROUTES.SERVICE_ORDER}/${serviceOrderId}`
      )
    })
  }, [serviceOrderId])

  const handleSubmitForm = useCallback(
    async ({ phone }) => {
      const cellToSend = phone === 'other' ? numberFieldText : phone
      const encodedText = window.encodeURIComponent(messageWhatsAppLinkToSend)

      dispatch(postSendRatingLink(serviceOrderId))
        .then(() => {
          window.open(
            `https://web.whatsapp.com/send?phone=${cellToSend}&text=${encodedText}`,
            '_blank'
          )
          GoBackServiceOrder()
        })
        .catch(error => {
          setToastInfo({
            isOpen: true,
            type: 'error',
            message: error.error ?? 'Erro ao enviar link de avaliação.',
          })
        })
    },
    [dispatch, numberFieldText, serviceOrderId, messageWhatsAppLinkToSend, GoBackServiceOrder]
  )

  const handlePhoneValidation = useCallback(() => {
    if (numberFieldText === null) {
      return false
    }
    if (numberFieldText?.replace(/[^\d]/g, '').length === 0) {
      return 'Campo obrigatório'
    }
    return !numbersValid(numberFieldText) && 'Um ou mais dígitos do telefone possuem problemas.'
  }, [numberFieldText])

  return (
    <Grid className={styles.page}>
      <HeaderTitle
        title="Enviar link de avaliação"
        backButtonAction={GoBackServiceOrder}
        className={styles.headerTitle}
      >
        <Grid className={styles.buttons}>
          <Button
            variant="secondary"
            color="primary"
            className={styles.button}
            onClick={() => setOpenModal(true)}
          >
            Enviar link depois
          </Button>
          <Button
            variant="primary"
            color="primary"
            className={styles.button}
            disabled={!numberSelected || (showTextField && handlePhoneValidation())}
            onClick={handleSubmit(handleSubmitForm)}
          >
            Enviar link
          </Button>
        </Grid>
      </HeaderTitle>
      <Grid component="form" className={styles.container}>
        <Typography component="p" variant="h3" className={styles.title}>
          Selecione um telefone de contato para enviar o link de avaliação do serviço
        </Typography>
        <Typography component="p" variant="h5">
          Para confirmar a finalização do seu serviço e receber seu pagamento, colete a avaliação do
          cliente pelo WhatsApp.
        </Typography>

        {serviceOrder?.contactNumber && (
          <>
            <Controller
              control={control}
              name="phone"
              as={
                <RadioGroup className={styles.radio} name="radio-buttons-group">
                  {serviceOrder?.contactNumbers.map(contactNumber => {
                    return (
                      numbersValid(contactNumber) && (
                        <FormControlLabel
                          control={<Radio />}
                          key={contactNumber}
                          label={phoneNumberMask(contactNumber)}
                          value={contactNumber}
                          color="success"
                        />
                      )
                    )
                  })}
                  <FormControlLabel
                    control={<Radio />}
                    label="Outro"
                    value="other"
                    color="success"
                  />
                </RadioGroup>
              }
            />

            {showTextField && (
              <Grid>
                <Textfield
                  className={styles.textfield}
                  label="Outro telefone"
                  error={!!handlePhoneValidation()}
                  value={numberFieldText}
                  onChange={newValue =>
                    setNumberFieldText(formatPhoneValueMask(newValue?.target.value))
                  }
                />
                <FormHelperText className={styles.errorMessage} error={!!handlePhoneValidation()}>
                  {handlePhoneValidation()}
                </FormHelperText>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Modal.Root open={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Content>
          <Modal.WarningIcon />
          <Modal.Title text="Enviar link depois" />
          <Modal.Text>Tem certeza que deseja enviar o link ao solicitante depois?</Modal.Text>
          <Modal.Text>
            Você só poderá finalizar o chamado depois que ele realizar a aprovação.
          </Modal.Text>
        </Modal.Content>
        <Modal.Actions className={styles.actionsModal}>
          <Modal.ButtonRed onClick={() => setOpenModal(false)}>Não</Modal.ButtonRed>
          <Modal.ButtonFullBlue onClick={GoBackServiceOrder}>Sim</Modal.ButtonFullBlue>
        </Modal.Actions>
      </Modal.Root>

      <Toast
        draggable
        open={toastInfo.isOpen}
        autoHideDuration={6000}
        onClose={() => setToastInfo(defaultToast)}
      >
        <Alert
          severity={toastInfo.type}
          title={toastInfo.message}
          onClose={() => setToastInfo(defaultToast)}
        />
      </Toast>

      <Footer />
    </Grid>
  )
}
